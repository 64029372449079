import React from 'react'
import { graphql, Link } from 'gatsby'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
const BlockContent = require('@sanity/block-content-to-react')
import SEO from '../components/utility/SEO'

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

const StyledBanner = styled(BackgroundImage)`
  display:grid;
  grid-template-columns:100%;
  padding:7.5vh 5vw;
  color:var(--color-white);

  text-align:center;
  opacity: 1 !important;
  background-size: cover;
  background-color: rgba(0,0,0,0.75);

  h1, p {
    align-self:center;
  }
  h1 {
    letter-spacing:2px;
  }
  p {
    font-size:1.2rem;
    font-weight:400;
    letter-spacing:1.5px;
    font-style:italic;
  }
`
const StyledBody = styled.div`
  display:grid;
  padding:5vh 5vw;
  max-width:1000px;
  margin: auto;
  a {
    justify-self:center;
  }
  button:hover {
    background-color:var(--color-accent-blue)
  }
  li {
    line-height:1.5rem;
  }
`

const PostTemplate = ({data}) => {
  const { sanitySettings } = data
  const { title, description, _rawBody, mainImage } = data.post
  return (
    <>
      <SEO title={`Blog | ${title}`} description={description} keywords={sanitySettings.keywords} />
      <StyledBanner
        fluid={mainImage.asset.fluid}
      >
        <h1>{title}</h1>
        <p>{description}</p>
      </StyledBanner>
      <StyledBody>
        <h1>{title}</h1>
        <BlockContent blocks={_rawBody} serializers={serializers} />
        <Link to="/blog">
          <button>Back to Blog</button>
        </Link>
      </StyledBody>
    </>
  )
}

export const query = graphql`
  query PostTemplateQuery($id: String!) {
    sanitySettings {
      keywords
    }
    post: sanityPost(_id: {eq: $id}) {
      _id
      title
      description
      _rawBody
      mainImage {
        asset {
          fluid(maxWidth:1920) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }

    }
  }
`

export default PostTemplate
